import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Img from 'gatsby-image'
import ctaVideo from '../videos/cta.mp4'
import Vendor from '../components/vendor/vendor'

class WhereToWatch extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="Where to Watch"
          bodyClass="body--light"
          description="The Edge Film 2019 will release in the UK and worldwide on July 22nd 2019"
        />
        <article>
          <div className="c-masthead">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">Where <br/>to Watch</h1>
                </div>
              </div>
            </div>
            <div className="c-masthead__image-container">
              <Img
                fluid={
                  this.props.data.title.childImageSharp.fluid
                }
                alt=""
                className="c-masthead__image"
              />
            </div>
          </div>
          <div className="c-screening-listing">
            <div className="container">
              <div className="row">
                <div className="c-screening-listing__container">
                  <div className="c-screening-listing__group">
                    <h2 className="c-screening-listing__title">Cinema screenings</h2>
                    <table className="c-screening-listing__table">
                      <tbody>
                        {this.props.data.allDatoCmsCinemaScreening.edges.map(({ node: screening }) => (
                          <tr>
                            <td>
                              <span>{screening.day}</span>
                              <span>{screening.date}</span>
                              <span>{screening.time}</span>
                            </td>
                            <td>
                              <h3>{screening.title}</h3>
                              {screening.subTitle !== '' && (
                                <h4>{screening.subTitle}</h4>
                              )}
                              <div
                                className="sheet__body"
                                dangerouslySetInnerHTML={{
                                  __html: screening.descriptionNode.childMarkdownRemark.html,
                                }}
                              />
                            </td>
                            <td><a href={screening.link} target="_blank" rel="noopener" className="c-btn c-btn--primary">Buy Tickets</a></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {this.props.data.allDatoCmsExclusiveScreening.length && (
                    <div className="c-screening-listing__group">
                      <h2 className="c-screening-listing__title">Exclusive screenings</h2>
                      <table className="c-screening-listing__table">
                        <tbody>
                          {this.props.data.allDatoCmsExclusiveScreening.edges.map(({ node: screening }) => (
                            <tr>
                              <td>
                                <span>{screening.day}</span>
                                <span>{screening.date}</span>
                                <span>{screening.time}</span>
                              </td>
                              <td>
                                <h3>{screening.title}</h3>
                                <h4>{screening.subTitle}</h4>
                                <div
                                  className="sheet__body"
                                  dangerouslySetInnerHTML={{
                                    __html: screening.descriptionNode.childMarkdownRemark.html,
                                  }}
                                />
                              </td>
                              <td><a href={screening.link} target="_blank" rel="noopener" className="c-btn c-btn--primary">Buy Tickets</a></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Vendor className="c-vendor--inpage" />
          <div className="c-cta c-cta--nomargin-bottom">
            <video
              src={ctaVideo}
              className="c-cta__video"
              autoPlay muted playsInline loop>
            </video>
            <div className="container">
              <div className="row">
                <div className="c-cta__link-container">
                  <Link className="c-cta__link" to="/host-a-screening">Host a Screening</Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    title: file(relativePath: { eq: "wheretowatch/title.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allDatoCmsExclusiveScreening(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title,
          day: date(formatString: "dddd")
          date: date(formatString: "DD MMMM YYYY")
          time: date(formatString: "h:mma")
          subTitle,
          descriptionNode {
            childMarkdownRemark {
              html
            }
          },
          link
        }
      }
    }
    allDatoCmsCinemaScreening(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title,
          day: date(formatString: "dddd")
          date: date(formatString: "DD MMMM YYYY")
          time: date(formatString: "h:mma")
          subTitle,
          descriptionNode {
            childMarkdownRemark {
              html
            }
          },
          link
        }
      }
    }
  }
`

export default WhereToWatch
